<template>
  <div ref="pageBox">
    <LabelContent title="积分规则管理" ref="LabelContent"> </LabelContent>
    <div class="tableBox">
      <el-table
        stripe
        ref="multipleTable"
        :data="tableData"
        class="center"
        v-loading="loading"
        :height="tableHeight + 'px'"
        :default-sort="{ prop: 'value', order: 'descending' }"
      >
        <el-table-column prop="ruleName" label="积分规则模块" />
        <el-table-column prop="dayLimitCount" label="每日获得次数" :sort-method="sortByDate" />
        <el-table-column prop="integral" label="积分" />
        <el-table-column label="是否启用" show-overflow-tooltip>
          <template slot-scope="scopes">
            <el-switch
              v-model="scopes.row.isActive"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="isActive(scopes.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="生成时间" />
        <el-table-column label="操作" width="100">
          <template slot-scope="slot">
            <i class="editBtn iconfont u-youjianbianji" @click="edit(slot.row)" />
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="积分规则管理" :visible.sync="dialogFormVisible">
        <el-form style="display: flex; flex-wrap: wrap; justify-content: left">
          <el-form-item label="每日获得次数" :label-width="formLabelWidth" class="stywid">
            <el-input
              v-model="form.dayLimitCount"
              type="number"
              :min="0"
              onKeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="积分" :label-width="formLabelWidth" class="stywid">
            <el-input
              v-model="form.integral"
              type="number"
              :min="1"
              onKeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
              placeholder="积分"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="editrows">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  name: "Factory",
  data () {
    return {
      totol: 0,
      loading: false,
      formLabelWidth: "100",
      dialogFormVisible: false,
      batchDeleteArr: [],
      tableHeight: 0, //表格高度
      testValue: 1,
      tableData: [],
      form: {
        dayLimitCount: "",
        integral: "",
        ruleId: "",
        ruleRemark: "",
      }
    }
  },

  mounted () {
    //设置表格高度
    this.$nextTick(() => {
      let page = this.$refs.pageBox.offsetHeight || 0 //卡片高度
      let SearchBox = this.$refs.SearchBox
        ? this.$refs.SearchBox.$el.offsetHeight
        : 0 //搜索框高度
      let LabelContent = this.$refs.LabelContent
        ? this.$refs.LabelContent.$el.offsetHeight
        : 0 //内容标题高度
      this.tableHeight = page - (SearchBox + LabelContent + 20 + 30) //padding -20,分页 -30
    })
    this.init()
  },
  methods: {
    async isActive (row) {
      let that = this
      let result = await that.$http.request({
        url: "/hw/admin/integral/rule/is_active/" + row.ruleId,
        method: "POST",
        params: {
          ruleId: row.ruleId,
          isActive: row.isActive,
        },
      })
      if (result.status == 100) {
        this.$message({
          message: '修改成功',
          type: 'success'
        })
      }
    },
    async edit (row) {
      this.dialogFormVisible = true
      this.form = {
        dayLimitCount: row.dayLimitCount,
        integral: row.integral,
        ruleId: row.ruleId,
        ruleRemark: "",
      }
    },
    async editrows () {
      let that = this
      let result = await that.$http.request({
        url: "/hw/admin/integral/rule/upd_rule/" + that.form.ruleId,
        method: "POST",
        params: that.form,
      })
      if (result.status == 100) {
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        this.dialogFormVisible = false
        this.init()
      }
    },
    async init () {
      let that = this
      that.loading = true
      let result = await that.$http.request({
        url: "/hw/admin/integral/rule/list",
        method: "GET",
        params: that.query,
      })
      if (result.status == 100) {
        let { data } = result
        that.tableData = data
        that.loading = false
      }
      console.log(that.tableData)
    },
    batchDelete () {
      let deleteData = this.batchDeleteArr.map((item) => {
        const { server_asset_id } = item
        return { server_asset_id }
      })
      this.deleteApi(deleteData)
    },
    //分页
    paginationChange (val) {
      console.log(val)
    },
    //表格行过滤
    formatterRow (row, column) {
    },
    //多选框选择 返回当前已选行数据(Array)
    handleSelectionChange (val) {
      this.batchDeleteArr = val
      console.log(val)
    },
    //从后台获取数据,重新排序
    sortChange (val) {
      // 详细文档参考： https://element.eleme.cn/2.13/#/zh-CN/component/table
      console.log(val)
    },
    //自定义排序规则 sort-method
    sortByDate (a, b) {
      console.log(a, b)
      if (a < b) {
        return -1
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.batchdel {
  display: none;
}
.el-button--primary {
  width: 4.5vw;
  height: 2vw;
  line-height: 0px;
}
.stywid {
  margin-left: 1vw;
}
</style>
